import {datadogRum} from '@datadog/browser-rum';
import {batch, signal} from '@preact/signals';

/**
 * Prefix for feature flags when stored in URL params or cart attributes
 */
export const prefix = '_ff_';

const features = signal<Map<string, string>>(new Map());

/**
 * Retrieves the value of a feature flag based on the provided key.
 * Because the feature flags are stored in a signal, this function can be used
 * to subscribe to changes in the feature flags.
 * @param key - The key of the feature flag to retrieve.
 * @returns The value of the feature flag, or undefined if the key is not found.
 * @example In a preact component
 * ```ts
 * const featureEnabled = getFeatureFlag('some_feature');
 * return <div>{featureEnabled ? 'Feature is enabled' : 'Feature is disabled'}</div>;
 * ```
 */
export function getFeatureFlag(key: string) {
  const value = features.value.get(key);
  // If the feature flag is not found, default to false
  // This ensures Datadog RUM can track every feature flag check, even if the flag is not set
  datadogRum.addFeatureFlagEvaluation(key, value ?? false);
  return value;
}

/**
 * Sets a feature flag with the specified key and value.
 * @param key - The key of the feature flag.
 * @param value - The value of the feature flag. Can be a string, boolean, or number.
 */
export function setFeatureFlag(key: string, value: string) {
  const newFeatureFlags = new Map(features.value);
  newFeatureFlags.set(key, value);
  features.value = newFeatureFlags;
}

/**
 * Sets the feature flags in the store.
 *
 * @param flags - A map of feature flag keys and their corresponding values.
 */
export function setFeatureFlags(flags: Map<string, string>) {
  batch(() => {
    for (const [key, value] of flags) {
      setFeatureFlag(key, value);
    }
  });
}

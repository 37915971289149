import type {
  Cart,
  CartLineItem,
  AddLineItemsResponse,
  LineItemInput,
  UpdateCartInput,
  UpdateLineItemInput,
} from '@/cart/types';

/**
 * Adds line items to the cart.
 * In addition to quantity and variant ID, line items can also include selling plans
 * and custom properties.
 */
export async function addLineItemsHandler(
  items: LineItemInput[]
): Promise<CartLineItem[]> {
  const data = (await post('/cart/add.js', {items})) as AddLineItemsResponse;
  return data.items;
}

/**
 * Clears the cart.
 * All line items are removed, but cart note and attributes are preserved.
 */
export function clearCartHandler(): Promise<Cart> {
  return post('/cart/clear.js') as Promise<Cart>;
}

/**
 * Gets the current cart.
 */
export function getCartHandler(): Promise<Cart> {
  return request('/cart.js') as Promise<Cart>;
}

/**
 * Updates the cart.
 * This is particularly useful for editing cart note and attributes.
 * It can also be used to perform batch quantity updates on all line items with a given variant id.
 * To update individual line item, use `updateLineItem` instead.
 * @see updateLineItem
 */
export function updateCartHandler(input: UpdateCartInput): Promise<Cart> {
  return post('/cart/update.js', input) as Promise<Cart>;
}

/**
 * Updates a line item in the cart.
 * The `quantity`, `properties`, and `selling_plan` fields may be updated.
 * Updating a line item with a quantity of zero will remove it from the cart.
 * The line item is identified by line number -- its 1-indexed position in the cart items list.
 * Alternatively, items may be identified by variant id.
 * This will update all line items with the given variant id.
 */
export function updateLineItemHandler(
  input: UpdateLineItemInput
): Promise<Cart> {
  return post('/cart/change.js', input) as Promise<Cart>;
}

function post(path: string, data?: Record<string, unknown>): Promise<unknown> {
  return request(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

async function request(path: string, options?: RequestInit): Promise<unknown> {
  const response = await fetch(path, options ?? {});

  if (!response.ok) {
    throw new Error(`${response.statusText} ${await response.text()}`);
  }

  return response.json();
}
